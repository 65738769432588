import React from "react";
import { createTheme, adaptV4Theme } from "@mui/material/styles";
import { Info, Replay } from "@mui/icons-material";
import Block from "@mui/icons-material/Block";
import CheckCircleOutline from "@mui/icons-material/CheckCircleOutline";

export const systemConst = {
  name: "イムラ Web発注システム",
};

export const productionDomain = "www.imura-weborder.com";
export const productionManageDomain = "imura-weborder-manage.azurewebsites.net";

export const webAUrl = "http://10.1.1.15/webab/w010_002.asp?";

export const RecaptchaSiteKeyV2 = "6Lch1uMZAAAAAJPTou0yk7JrdpHlI8K25Rte90gB";

/**
 * 定数
 */
export const imageConst = {
  image_compress_size_200KB: 0.2, // 画像の圧縮（200kb）
  image_compress_size_20KB: 0.02, // 画像の圧縮（20kb）
  image_compress_size_100KB: 0.1, // 画像の圧縮（100kb）
};

/**
 * 正規表現
 */
export const validationConst = {
  phone_reg_exp: /^0\d{1,4}-\d{1,4}-\d{3,4}$/, // 電話番号の正規表現
  phone_reg_exp_no_hyphen: /^0\d{9,11}$/, // 電話番号の正規表現
  phone_reg_exp_nullable: /^0\d{1,4}-\d{1,4}-\d{3,4}|^()$/, // 電話番号の正規表現(ハイフン有null許容)
  ip_reg_exp: /^\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}$/, // IPの正規表現
  postal_reg_exp: /^[0-9]{3}-[0-9]{4}$/, // 郵便番号の正規表現
  orderNumByCustomer_reg_exp: /^([A-Z0-9ｦ-ﾟ!-/:-@¥[-`{-~]{1,10})$|^$/, // 注文Noの正規表現（半角英大文字・半角数値・半角カナ・カンマ以外の半角記号　10文字以下）
  orderNumByCustomer_reg_not_com: /^([^,]{1,10})$|^$/, // 注文Noの正規表現（半角英大文字・半角数値・半角カナ・カンマ以外の半角記号　10文字以下）
};
export const ApprovalOperationKind = {
  request: {
    key: "request",
    value: 1,
    name: "申請",
    color: "primary",
    icon: <Info style={{ color: "white" }} />,
  }, //申請
  approval: {
    key: "approval",
    value: 2,
    name: "承認",
    color: "success",
    icon: <CheckCircleOutline style={{ color: "white" }} />,
  }, //承認
  reject: {
    key: "reject",
    value: 3,
    name: "差戻",
    color: "error",
    icon: <Block style={{ color: "white" }} />,
  }, //差し戻し
  pullback: {
    key: "pullback",
    value: 4,
    name: "引戻",
    color: "warning",
    icon: <Replay style={{ color: "white" }} />,
  }, //引き戻し
};
/**
 * 値を元に承認の操作オブジェクトを取得します
 * @param {*} value
 * @returns
 */
export const GetApprovalOperation = (value) => {
  const aok = ApprovalOperationKind;
  for (let key in aok) {
    if (aok[key].value === value) return aok[key];
  }
  return null;
};

export const ApprovalKind = {
  order: "order",
  shipping: "shipping",
  manufacture: "manufacture",
};

export const CustomerId = "会社CD";
export const CustomerName = "会社名";
export const CustomerBranchOfficeName = "担当部署";
export const SenderDefaultName = "イムラ";

/**
 * 承認ルートの区分
 * 1:発注依頼 , 2:在庫出荷依頼 , 3: 在庫作成依頼
 */
export const ApprovalRouteDivision = {
  Order: 1,
  ShippingStock: 2,
  CreateStock: 3,
};

/**
 * 金額計算区分
 */
export const PriceKind = {
  //単価
  unitPrice: 0,
  //合価
  summaryPrice: 1,
};
/**
 * 製品区分
 */
export const ProductKind = {
  //既製品
  standard: 0,
  //別製品
  custom: 1,
  //在庫出荷品
  shipping: 2,
};

/**
 * 製品区分
 */
export const OrderProcKind = {
  //別製品
  custom: 0,
  //在庫品(預り品)
  Deposited: 2,
  //在庫品(一般品)
  General: 3,
};

/**
 * 日付区分
 */
export const DateSegment = {
  // 注文日
  orderDate: 0,
  // 出荷日
  shippingDate: 1,
};

/**
 * SessionStorageのキー
 * Login.jsにて、ログインボタン押下時にいったんすべて削除されます。
 */
export const sessionStorageKeys = {
  CustomerSettingSelectDrawerPageNo: "IEOS_Customer_Select_Drawer_Page_No",
  CustomerSettingSelectDrawerSearchItem: "IEOS_Customer_Select_Drawer_Search_Item",
  CustomersSearchItem: "IEOS_Customers_Search_Item",
  CustomersSearchItemInStandardProductSearch: "IEOS_Customers_Search_Item_StandardProductSearch",
  CustomersSearchItemInLoginMenu: "IEOS_Customers_Search_Item_LoginMenu",
  CustomersSearchItemInCustomeProductSearch: "IEOS_Customers_Search_Item_CustomeProductSearch",
  CustomersSearchItemInUsersSearch: "IEOS_Customers_Search_Item_In_Users_Search",
  Revision: "IEOS_Revision", // バージョン情報
  ServerRevision: "IEOS_Server_Revision", // サーバーバージョン情報
  Reload: "IEOS_Reload", // リロードフラグ 値がある時は強制リロードした後
  MasterUpdatePageNo: "IEOS_MasterUpdate_Page_No",
  StockCreateHistoryPageNo: "IEOS_StockCreateHistory_Page_No",
  StockShippingHistoryPageNo: "IEOS_StockShippingHistory_Page_No",
  UsersPageNo: "IEOS_Users_Page_No",
  CustomersPageNo: "IEOS_Customers_Page_No", // 管理サイト　得意先管理のページを保持する
  UserEdit: "IEOS_UserEdit",
  InformationListPageNo: "IEOS_InformationList_Page_No",
  InformationRegister: "IEOS_InformationRegister",
  OrderIndexPageNo: "IEOS_OrderIndex_Page_No",

  EntryDefaultValues: "IEOS_Entry_Default_Values",
  RolesPageNo: "IEOS_Roles_Page_No",
  DoctorsPageNo: "IEOS_Doctors_Page_No",
  ExamCardsPageNo: "IEOS_ExamCards_Page_No",
  InformationListAdminPageNo: "IEOS_InformationList_Admin_Page_No",
  StockCheckIds: "IEOS_Stock_Check_Ids", //サーバーサイドで出荷/作成依頼時にチェックされた在庫品IDを保存　※在庫一覧複数選択レイアウトの場合使用する
  ShippingStockProductIds: "IEOS_Shipping_Stock_Product_Ids", //出荷依頼押下時に選択されている在庫品 ※サーバー側で現在StockCheckIdsを使用中、不要の可能性あり
  CreateStockProductIds: "IEOS_Create_Stock_Product_Ids", //作成依頼押下時に選択されている在庫品 ※サーバー側で現在StockCheckIdsを使用中、不要の可能性あり
  NoticesPageNo: "IEOS_Notices_Page_No",
  BookingsSelectedDate: "IEOS_Bookings_Selected_Date", //予約一覧画面で選択された日付
  BookingsSelectedDept: "IEOS_Bookings_Selected_Dept", //予約一覧画面で選択された診療科
  BookingsSelectedTime: "IEOS_Bookings_Selected_Time", //予約一覧画面で選択された時間枠
  NewPatient: "IEOS_New_Patient", // 診察券を持っていない患者（初診）かどうかの判定
  ReservationTableDeptId: "IEOS_Reservation_Table_Dept_Id", // 予約状況テーブルの診療科を保持
  PreConfirmSelectValue: "IEOS_Pre_Confirm_Select_Value", //事前画面の選択値を保持
};

/**
 * LocalStorageのキー
 */
export const localStorageKeys = {
  IsShowVerticalCustom: "IEOS_SEARCH_CUSTOM_PRODUCTS_IS_SHOW_VERTICAL",
  IsShowVerticalStandard: "IEOS_SEARCH_STANDARD_PRODUCTS_IS_SHOW_VERTICAL",
  IsShowVerticalStockCustom: "IEOS_SEARCH_STOCK_CUSTOM_PRODUCTS_IS_SHOW_VERTICAL",
  SortOrder: "IEOS_SORT_ORDER",
  SortStockShipping: "IEOS_SORT_STOCK_SHIPPING",
  SortStockCreate: "IEOS_SORT_STOCK_CREATE",
  IsShowAllMessages: "IEOS_IS_SHOW_ALL_MESSAGES",
  LoginJWT: "IEOS_0001",
};

/**
 * 製品詳細ドロワーに渡す製品詳細テーブルの行名
 */
export const standardItemTitles = [
  "製品No",
  "送状品名（漢字）",
  "封筒巾",
  "封筒天地",
  "封筒マチ",
  "窓巾１",
  "窓天地１",
  "窓位置Ａ",
  "窓位置Ｂ",
  "紙名１",
  "紙名２",
  "紙厚",
  "色名",
  "製品情報１",
  "製品情報２",
  "入数",
];

/**
 * 予約の一連の画面のパス
 */
export const reservationPath = "/r/";

/**
 * 予約枠設定画面のカレンダーに利用する週のリソースIDとリソース名のマッピング
 */
export const weekDayMap = [
  { resourceId: 1, resourceTitle: "月" },
  { resourceId: 2, resourceTitle: "月(祝)" },
  { resourceId: 3, resourceTitle: "火" },
  { resourceId: 4, resourceTitle: "火(祝)" },
  { resourceId: 5, resourceTitle: "水" },
  { resourceId: 6, resourceTitle: "水(祝)" },
  { resourceId: 7, resourceTitle: "木" },
  { resourceId: 8, resourceTitle: "木(祝)" },
  { resourceId: 9, resourceTitle: "金" },
  { resourceId: 10, resourceTitle: "金(祝)" },
  { resourceId: 11, resourceTitle: "土" },
  { resourceId: 12, resourceTitle: "土(祝)" },
  { resourceId: 13, resourceTitle: "日" },
  { resourceId: 14, resourceTitle: "日(祝)" },
];

/**
 * 予約枠設定画面の適用週とタイトルのマッピング
 */
export const otherWeeksMap = [
  { key: "99", title: "毎週" },
  { key: "1", title: "第1週" },
  { key: "2", title: "第2週" },
  { key: "3", title: "第3週" },
  { key: "4", title: "第4週" },
  { key: "5", title: "第5週" },
  { key: "6", title: "第6週" },
];

/**
 * 予約枠設定画面のカレンダーに利用するイベント枠の設定
 */
export const calendarStyle = (event, start, end, isSelected) => {
  const colors = ["#34A853", "#4285F4"];
  var backgroundColor;
  var border = "0px";
  var fontColor = "white";
  if (String(event.id).substr(0, 3) === "new") {
    // 新規追加分は、色固定
    backgroundColor = "white";
    border = "1px solid #6473D3";
    fontColor = "#6473D3";
  } else if (event.allDay === true) {
    // 全日分も色固定
    backgroundColor = "#BB001B";
  } else {
    // その他は、曜日ごとの色にする
    var colorId = Number(event.resourceId) % 2;
    backgroundColor = colors[colorId];
  }

  var style = {
    backgroundColor: backgroundColor,
    color: fontColor,
    border: border,
    display: "block",
  };
  return {
    style: style,
  };
};

/**
 * 必須の※マークを赤色にする設定
 */
export const requireRed = createTheme(
  adaptV4Theme({
    overrides: {
      MuiFormLabel: {
        asterisk: {
          color: "#db3131",
          "&$error": {
            color: "#db3131",
          },
        },
      },
    },
  })
);

/**
 * 利用規約
 */
export const termOfUseHtml = ``;

/**
 * 配達先の納品先区分
 * 0:設定なし , 1:得意先に納品(受注時に入力した納品先) , 2:直送先に納品 , 3: 配送先指示
 */
export const DeliveryCategorys = {
  NotSet: 0,
  Customer: 1,
  Direct: 2,
  DestinationFree: 3,
};

/**
 * 一覧系画面の並び順
 * 0:注文日時が古い順, 1:注文日時が新しい順
 */
export const SortOrder = {
  OldOrder: "0",
  NewOrder: "1",
};

export const OtherGropuId = 99999;
